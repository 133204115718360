import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get targets() {
		return ['toggle'];
	}

	connect() {
		this.toggleTargets.forEach(toggle => {
			toggle.addEventListener('click', () => {
				document.body.classList.toggle('filters--opened');
			});
		});

		this.element.querySelectorAll('.show-more').forEach(showMore => {
			showMore.addEventListener('click', () => {
				showMore.parentNode.querySelector('.filter-filters--hidden').classList.toggle('d-block');
				showMore.remove();
			});
		});
	}
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        for (const trigger of this.element.querySelectorAll('.expand-trigger')) {
            trigger.addEventListener('click', () => {
                const expanded = trigger.classList.contains('trigger-expanded');

                if (expanded) {
                    trigger.classList.remove('trigger-expanded');
                    this.element.classList.remove('trigger-expanded');
                } else {
                    trigger.classList.add('trigger-expanded');
                    this.element.classList.add('trigger-expanded');
                }

                for (const target of this.element.querySelectorAll('.expand-target')) {
                    let totalChildrenHeight = 0;

                    if (!expanded) {
                        for (const child of target.children) {
                            totalChildrenHeight += child.clientHeight;
                        }
                    }

                    target.style.height = `${totalChildrenHeight}px`;

                    if (expanded) {
                        target.classList.remove('target-expanded');
                    } else {
                        target.classList.add('target-expanded');
                    }
                }
            });
        }
    }
}

import { Controller } from '@hotwired/stimulus';
import Axios from '../axios';

export default class extends Controller {

	connect() {
		this._lang = document.documentElement.lang.split('-')[0];
	}

	async selectClub(event) {
		const element = event.currentTarget;
		const clubId = element.dataset.clubId;
		const url = element.dataset.route + '&rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			id: clubId
		})
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

		if (response && response.redirect) {
			window.location.href = response.redirect;
		} else {
			element.classList.remove('loading');
		}
	}
}

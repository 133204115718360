import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		this._sync = this.element.dataset.sync ? JSON.parse(this.element.dataset.sync) : null;

		this.element.querySelectorAll('.switch').forEach((switchEl, index) => {
			switchEl.addEventListener('click', () => this.toggleContent(switchEl, index));
		});
	}

	toggleContent(switchEl, index, sync = true) {
		if (switchEl.classList.contains('active')) {
			return;
		}

		const hideEls = document.querySelectorAll(switchEl.dataset.hide);
		const showEl = document.querySelector(`.${switchEl.dataset.show}`);

		hideEls.forEach(hideEl => {
			hideEl.classList.remove('active');
		});

		showEl.classList.add('active');

		if (sync) {
			this.element.querySelector('.active').classList.remove('active');
		} else {
			switchEl.parentElement.querySelector('.active').classList.remove('active');
		}

		switchEl.classList.add('active');

		if (this._sync !== null && sync) {
			this._sync.forEach((selector) => {
				const syncSwitcher = document.querySelector(selector);
				if (syncSwitcher) {
					const nthChild = index+1;
					const switcher = syncSwitcher.querySelector(`.switch:nth-child(${nthChild})`);

					if (switcher && !switcher.classList.contains('active')) {
						this.toggleContent(switcher, index, false);
					}
				}
			});
		}
	}
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get targets() {
		return ['total', 'input'];
	}
	connect() {
		const maxWords = this.element.dataset.max ? parseInt(this.element.dataset.max) : 250;

		this.inputTarget.addEventListener('keyup', () => {
			const val = this.inputTarget.value;
			if (val.length > maxWords) {
				this.inputTarget.value = val.substr(0, maxWords);
				return false;
			}

			this.totalTarget.innerHTML = val.length;
		});
	}
}

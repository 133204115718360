import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get targets () {
		return ['select'];
	}

	connect() {
		if (window.location.href.includes('/page/')) {
			const scrollToId = this.element.dataset.scrollToId;

			if (scrollToId) {
				const el = document.getElementById(scrollToId);
				if (el) {
					const y = el.getBoundingClientRect().top + window.scrollY - 100;
					window.scroll({
						top: y,
						behavior: 'smooth'
					});
				}
			}
		}
	}

	selectTargetConnected(element) {
		element.addEventListener('change', () => {
			window.location.href = element.value;
		});
	}
}

import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
	connect() {
		this._config = this.element.dataset.config ? JSON.parse(this.element.dataset.config) : null;

		if (!this._config) {
			return;
		}

		const appendToSelector = this.element.dataset.appendTo;

		this.element.addEventListener('click', () => {
			let el = this.buildNode(this._config);
			this.recurse(this._config, el);
			const appendTo = document.querySelector(appendToSelector);
			appendTo.appendChild(el);

			if (el.classList.contains('removable')) {
				el.querySelector('.remove').addEventListener('click', () => {
					el.remove();
				});
			}
		});
	}

	// todo: appendChild to direct parent rather than top parent
	recurse(config, el) {
		for (const [key, value] of Object.entries(config)) {
			if (key === 'children') {
				value.forEach((c) => {
					const node = this.buildNode(c);
					el.appendChild(node);
					this.recurse(c);
				});
			}
		}
	}

	buildNode(config) {
		const el = document.createElement(config.tag);

		for (const [key, value] of Object.entries(config)) {
			if (key === 'children') continue;

			if (key.includes('()')) {
				el[key.replace('()', '')](value);
			} else {
				el[key] = value;
			}
		}

		return el;
	}
}

import { Controller } from '@hotwired/stimulus';
import { slideToggle } from '../helpers';

export default class extends Controller {
	connect() {
		const toggles = this.element.querySelectorAll('.accordion-head');
		toggles.forEach(toggle => {
			toggle.addEventListener('click', () => {
				const item = toggle.parentElement;
				slideToggle(item.querySelector('.accordion-body'));
				item.classList.toggle('active');
			});
		});
	}
}

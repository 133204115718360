import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const lang = document.documentElement.lang.split('-')[0];

        this.element.classList.add('loading');

        const postID = this.element.dataset.pid;
        const blockID = this.element.dataset.bid;

        const queryParams = window.location.search.slice(1);
        let url = `/wp-json/api/async-render-block?pid=${postID}&bid=${blockID}&rest_language=${lang}`;

        if (queryParams) {
            url += `&${queryParams}`;
        }

        fetch(url)
            .then(response => response.json())
            .then(response => {
                this.element.classList.remove('loading');

                if (response.success) {
                    this.element.innerHTML = response.data.content;
                }
            })
            .catch(error => {
                console.error(error);
                this.element.classList.remove('loading');
            });
    }
}

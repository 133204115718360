import { Controller } from '@hotwired/stimulus';
import Axios from '../axios';
import { getMeta } from '../helpers';

export default class extends Controller {
    setupFormAction(action, actionData) {
        switch (action) {
            case 'next_step':
                this.next_step(actionData);
                break;

            case 'enter_password':
                this.enter_password(actionData);
                break;

            case 'show_element':
                this.show_element(actionData);
                break;
        }
    }

    connect() {
        this.currentLanguageCode = document.documentElement.lang.split('-')[0];
        this.element.addEventListener('submit', async (e) => {
            e.preventDefault();

            if (this.element.classList.contains('loading')) {
                return;
            }

            this.removeFormErrors();
            this.element.classList.add('loading');

            let langParam;
            if (this.element.action.includes('?')) {
                langParam = `&rest_language=${this.currentLanguageCode}`;
            } else {
                langParam = `?rest_language=${this.currentLanguageCode}`;
            }

            const url = `${this.element.action}${langParam}`;
            const method = this.element.method.toLowerCase();
            const data = new FormData(this.element);

            let response = null;

            try {
                if (method === 'get') {
                    response = await Axios.get(url);
                } else if (method === 'post') {
                    response = await this.handlePostSubmit(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
                }

                response = response.data.data;
            } catch (error) {
                response = error.response.data.data;
            }

            this.removeFormNotes();

            if (response && response.errors && Object.keys(response.errors).length) {
                this.setupFormErrors(response.errors);
            } else if (response && response.general_error) {
                this.setupFormGeneralError(response.general_error);
            } else if (response && response.general_success) {
                this.setupFormGeneralSuccess(response.general_success);
            } else if (response && response.redirect) {
                window.location.href = response.redirect;

                const blankLink = document.querySelector('#blankLink');
                if (response.redirect_blank && blankLink) {
                    blankLink.href = response.redirect_blank;
                    blankLink.click();
                }
            } else if (response && response.reload) {
                window.location.reload();
            } else if (response && response.action) {
                this.setupFormAction(response.action, response.action_data);
            } else {
                this.setupFormUnexpectedError();
            }
        });
    }

    handlePostSubmit(url, data, config) {
        if (window.grecaptcha && data instanceof FormData) {
            return new Promise((resolve) => {
                window.grecaptcha.ready(() => {
                    resolve(window.grecaptcha.execute(document.querySelector('meta[name="global_recaptcha_site_key"]').content, { action: 'submit' }));
                });
            }).then(recaptchaToken => {
                data.append('recaptcha', recaptchaToken);

                return Axios.post(url, data, config);
            });
        }

        return Axios.post(url, data, config);
    }

    setupFormUnexpectedError() {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = getMeta('global_axios_validator_error');
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    setupFormGeneralSuccess(success) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-success');
        messageNode.innerHTML = success;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
        // reset form values
        if (!this.element.classList.contains('form-no-reset')) {
            this.element.reset();
        }
    }

    setupFormGeneralError(error) {
        const messageNode = document.createElement('span');
        messageNode.classList.add('general-error');
        messageNode.innerHTML = error;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    setupFormErrors(errors) {
        let topMostError = null;

        for (let i = 0, keys = Object.keys(errors), ii = keys.length; i < ii; i++) {
            const input = this.element.querySelector(`[name='${keys[i]}']`);

            if (input) {
                input.classList.add('invalid');
                const reservedErrorElement = keys[i].includes('[') ? null : this.element.querySelector(`span.input-error--${keys[i]}`);

                if (reservedErrorElement) {
                    reservedErrorElement.innerHTML = errors[keys[i]];
                    reservedErrorElement.style.display = 'inline-block';
                } else {
                    const messageNode = document.createElement('span');
                    messageNode.classList.add('input-error');
                    messageNode.innerHTML = errors[keys[i]];

                    if (input.tagName.toLowerCase() === 'select') {
                        input.parentNode.appendChild(messageNode);
                    } else {
                        if (input.classList.contains('checkbox-field')) {
                            input.parentElement.parentNode.insertBefore(messageNode, input.parentElement.nextSibling);
                        } else {
                            input.parentNode.insertBefore(messageNode, input.nextSibling);
                        }
                    }

                    if (topMostError) {
                        if (topMostError.getBoundingClientRect().top > input.getBoundingClientRect().top) {
                            topMostError = input;
                        }
                    } else {
                        topMostError = input;
                    }
                }
            }
        }

        this.element.classList.remove('loading');
    }

    removeFormErrors() {
        const invalidFields = this.element.querySelectorAll('.invalid, .input-error');
        invalidFields.forEach(invalidField => {
            if (invalidField.classList.contains('invalid')) {
                invalidField.classList.remove('invalid');
            } else if (invalidField.classList.contains('input-error--reserved')) {
                console.log('none');
                invalidField.style.display = 'none';
            } else {
                invalidField.remove();
            }
        });
    }

    removeFormNotes() {
        const generalErrors = this.element.querySelectorAll('.general-error');
        for (const el of generalErrors) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const generalSuccess = this.element.querySelectorAll('.general-success');
        for (const el of generalSuccess) {
            if (!el.classList.contains('axios-no-remove')) {
                el.remove();
            }
        }

        const fields = this.element.querySelectorAll('.input');
        for (const el of fields) {
            el.classList.remove('invalid');
        }

        const messageFields = this.element.querySelectorAll('.input-error');
        for (const el of messageFields) {
            if (el.classList.contains('input-error--reserved')) {
                el.style.display = 'none';
            } else {
                el.remove();
            }
        }
    }

    next_step(data) {
        if (data.prefill) {
            for (let i = 0, keys = Object.keys(data.prefill), ii = keys.length; i < ii; i++) {
                const input = document.querySelector(`[name="${keys[i]}"]`);

                if (input) {
                    if (keys[i] === 'activation_date') {
                        input._flatpickr.set('minDate', data.prefill[[keys[i]]].min);
                        input._flatpickr.set('maxDate', data.prefill[[keys[i]]].max);
                        input._flatpickr.setDate(data.prefill[[keys[i]]].value);
                    } else {
                        const inputType = input.getAttribute('type');
                        if (inputType === 'radio') {
                            document.querySelector(`[name="${keys[i]}"][value="${data.prefill[[keys[i]]]}"]`).checked = true;
                        } else {
                            input.value = data.prefill[[keys[i]]];
                        }
                    }
                }
            }
        }

        document.querySelectorAll(`.${data.hidden_step}`).forEach(step => {
            step.classList.remove('d-block');
            step.classList.add('d-none');
        });

        document.querySelectorAll(`.${data.visible_step}`).forEach(step => {
            step.classList.add('d-block');
            step.classList.remove('d-none');
        });

        if (document.querySelector('input[name="existing_user"]')) {
            document.querySelector('input[name="existing_user"]').value = 0;
        }

        if (document.querySelector('#passwordField')) {
            document.querySelector('#passwordField').classList.add('d-none');
        }

        if (document.querySelector('input[name="password"]')) {
            document.querySelector('input[name="password"]').value = '';
        }

        if (data.hide_element) {
            const el = document.querySelector(data.hide_element);

            if (el) {
                el.classList.add('d-none');
                el.querySelectorAll('input, select').forEach(input => {
                    input.value = '';
                });
            }
        }

        if (data.update_element) {
            const els = document.querySelectorAll(data.update_element.element);

            if (els) {
                els.forEach(el => {
                    el.innerHTML = data.update_element.value;
                });
            }
        }

        this.element.classList.remove('loading');
    }

    enter_password(data) {
        document.querySelector('input[name="existing_user"]').value = 1;
        document.querySelector('#passwordField').classList.remove('d-none');

        const messageNode = document.createElement('span');
        messageNode.classList.add('general-success');
        messageNode.innerHTML = data.message;
        this.element.appendChild(messageNode);
        this.element.classList.remove('loading');
    }

    show_element(data) {
        const element = document.querySelector(data.element);

        if (element) {
            element.classList.remove('d-none');
        }

        this.element.classList.remove('loading');
    }
}

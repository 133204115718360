import { Controller } from '@hotwired/stimulus';

const availableModalControllers = [];

export default class extends Controller {
    static get targets () {
        return ['modal', 'button'];
    }

    connect() {
        this._currentModal = null;
    }

    modalTargetConnected(element) {
        availableModalControllers.push(element);

        this.modalScrollContainer = element.querySelector('.modal-scroll-container');
        this.modalDialogContainer = element.querySelector('.modal-dialog');
        this.modalContent = element.querySelector('.modal-content');

        for (const close of element.querySelectorAll('.modal-close')) {
            close.addEventListener('click', () => this.hideModal(element));
        }

        this.modalScrollContainer.addEventListener('mousedown', (e) => {
            if (e.target === this.modalScrollContainer) {
                this.hideModal(element);
            }
        });

        this.modalDialogContainer.addEventListener('mousedown', (e) => {
            if (e.target === this.modalDialogContainer) {
                this.hideModal(element);
            }
        });

        this.modalContent.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }

    buttonTargetConnected(element) {
        const target = element.getAttribute('data-modal-el');
        element.addEventListener('click', (e) => {
            e.preventDefault();
            this.showModal(document.querySelector(target));
        });
    }

    showModal(modal, callback) {
        this.showModalHandle(modal, callback);
    }

    showModalHandle(element, callback) {
        element.classList.add('modal-show');

        let op = 0.1;
        element.style.display = 'block';
        const timer = setInterval(() => {
            if (op >= 1) {
                clearInterval(timer);

                if (callback) {
                    callback();
                }
            }
            element.style.opacity = op;
            element.style.filter = `alpha(opacity=${op * 100})`;
            op += op * 0.1;
        }, 5);

        document.getElementsByTagName('html')[0].classList.add('modal-visible');
    }

    hideModal(element, callback) {
        element.classList.remove('modal-show');

        let op = 1;
        const timer = setInterval(() => {
            if (op <= 0.1) {
                clearInterval(timer);
                element.style.display = 'none';

                if (callback) {
                    callback();
                }
            }
            element.style.opacity = op;
            element.style.filter = `alpha(opacity=${op * 100})`;
            op -= op * 0.1;
        }, 5);

        document.getElementsByTagName('html')[0].classList.remove('modal-visible');
    }
}

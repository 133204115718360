import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	connect() {
		const inputs = this.element.querySelectorAll('input[type=text], input[type=email], input[type=tel]');
		const selectsAndCheckboxes = this.element.querySelectorAll('select, input[type=checkbox], input[type=radio]');
		let debounce = null;
		const self = this;

		for (const input of inputs) {
			if (!input.parentNode.querySelector('button[type=submit]')) {
				input.addEventListener('keydown', () => {
					clearTimeout(debounce);

					debounce = setTimeout(function () {
						self.element.submit();
					}, 500);
				});
			}

			const clearInput = input.parentNode.querySelector('.clear-input');

			if (typeof clearInput != 'undefined' && clearInput != null) {
				clearInput.addEventListener('click', () => {
					input.value = '';
					self.element.submit();
				});
			}
		}

		for (const select of selectsAndCheckboxes) {
			select.addEventListener('change', () => {
				self.element.submit();
			});
		}
	}
}

import intlTelInput from 'intl-tel-input';
import { onDomReady } from './helpers';

onDomReady(() => {
	const telInputs = document.querySelectorAll('input[type=tel]');
	Array.from(telInputs).forEach(telInput => {
		let input = intlTelInput(telInput, {
			initialCountry: 'lt',
			preferredCountries: ['lt', 'lv', 'ee'],
			separateDialCode: false,
			customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
				if (selectedCountryData.iso2 === 'lt') {
					return '6xxxxxxx';
				} else if (selectedCountryData.iso2 === 'lv') {
					return '2xxxxxxx';
				} else if (selectedCountryData.iso2 === 'ee') {
					return '5xxxxxxx';
				}

				return selectedCountryPlaceholder;
			},
			utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
		});

		setTimeout(() => {
			isValidTel(telInput, input);
		}, 1000);

		telInput.addEventListener('keyup', () => {
			isValidTel(telInput, input);
		});

		telInput.addEventListener('countrychange', () => {
			isValidTel(telInput, input);
		});
	});
});

function isValidTel(telInput, input) {
	if (telInput.value === '') {
		telInput.classList.remove('invalid');
		telInput.classList.remove('valid');
	}
	else if (input.isValidNumber() === false) {
		telInput.classList.add('invalid');
		telInput.classList.remove('valid');
	} else {
		telInput.classList.remove('invalid');
		telInput.classList.add('valid');

		telInput.value = input.getNumber();
	}
}

import { getMeta } from '../helpers';

export default function() {
	const self = this;

	self.settings.shouldOpen = true; // make sure the input is shown even if there are no options to display in the dropdown

	self.hook('after','setup',()=>{
		self.focus_node	= self.control;
		self.control_input.classList.add('dropdown-checkbox');

		const div = getDom('<div class="dropdown-checkbox">');
		div.append(self.control_input);
		self.dropdown.insertBefore(div, self.dropdown.firstChild);
	});

	self.hook('after', 'setupTemplates', () => {
		const orig_render_dropdown = self.settings.render.dropdown;
		const allValues = [];
		const selected = [];

		self.input.querySelectorAll('option').forEach(option => {
			allValues.push(option.value);

			if (option.selected) {
				selected.push(option.value);
			}
		});

		self.settings.render.dropdown = (data, escape_html) => {
			const rendered = getDom(orig_render_dropdown.call(self, data, escape_html));


			let allSelected = allValues.length === selected.length;
			let label = document.createElement('label');
			label.innerHTML = getMeta('select_all');

			let checkbox = document.createElement('input');
			checkbox.type = 'checkbox';
			checkbox.checked = !!allSelected;

			checkbox.classList.add('checkbox-field');
			checkbox.classList.add('checkbox-small');
			checkbox.classList.add('checkbox-primary');
			label.prepend(checkbox);



			checkbox.addEventListener('change', (e) => {
				e.stopPropagation();
				let values = [];
				const checked = e.target.checked;
				e.target.parentElement.parentElement.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
					checkbox.checked = checked;
					if (checked) {
						values.push(checkbox.parentElement.getAttribute('data-value'));
					}
				});

				self.setValue(values);
			});

			rendered.prepend(label);
			return rendered;
		};
	});
}

function getDom(query) {

	if( query.jquery ){
		return query[0];
	}

	if( query instanceof HTMLElement ){
		return query;
	}

	if( isHtmlString(query) ){
		var tpl = document.createElement('template');
		tpl.innerHTML = query.trim(); // Never return a text node of whitespace as the result
		return tpl.content.firstChild;
	}

	return document.querySelector(query);
}

function isHtmlString(arg) {
	if ( typeof arg === 'string' && arg.indexOf('<') > -1 ){
		return true;
	}
	return false;
}

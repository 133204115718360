import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        if (this.element.hasAttribute('data-toggle')) {
            const toggleElement = document.querySelector(`.${this.element.getAttribute('data-toggle')}`);

            if (toggleElement) {
                this.element.addEventListener('click', () => {
                    if (toggleElement.classList.contains('d-none')) {
                        toggleElement.classList.remove('d-none');
                    } else {
                        toggleElement.classList.add('d-none');
                    }
                });
            }
        }
    }
}

import {Controller} from '@hotwired/stimulus';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper';

export default class extends Controller {
	static get targets () {
		return ['currentCount', 'next', 'prev', 'pagination', 'progressGroup', 'pnWrapper'];
	}

	initialize() {
		this._swiper = null;
		this._shouldAttachEvents = true;

		this._progress = 0;
		this._progressAddition = 0;
		this._progressTimestamp = 0;
		this._config = {};
	}

	connect() {
		this.load();
	}

	disconnect() {
		if (this._swiper) {
			this._swiper.destroy();
			this._swiper = null;
		}
	}
	async load() {
		this._config = this.element.dataset.config ? JSON.parse(this.element.dataset.config) : {};
		const config = this._config;

		config.watchOverflow = true;
		if (config.autoplay) {
			Swiper.use([Autoplay]);
			this._progressAddition = 1 / ((config.autoplay.delay + config.speed) / 200);
		}

		if (config.navigation || (this.hasNextTarget && this.hasPrevTarget)) {
			Swiper.use([Navigation]);
			config.navigation = {
				nextEl: this.nextTarget,
				prevEl: this.prevTarget
			};
		}

		if (config.pagination || this.hasPaginationTarget) {
			Swiper.use([Pagination]);
			config.pagination = {
				el: this.paginationTarget
			};
		}

		if (config.screen) {
			if (window.screen.width < config.screen) {
				delete config.screen;
				this._swiper = new Swiper(this.element, Object.keys(config).length > 0 ? config : undefined);
			}
		} else {
			this._swiper = new Swiper(this.element, Object.keys(config).length > 0 ? config : undefined);
		}

		this.updateCurrent();

		if (this.hasProgressGroupTarget) {
			this._swiper.on('slideChange', () => {
				this._progress = 0;
				this.progressGroupTarget.setAttribute('data-slider-progress-group-current-value', this.getCurrent());
				this.progressGroupTarget.setAttribute('data-slider-progress-group-progress-value', this._progress);
				requestAnimationFrame((t) => this.updateProgress(t));
			});

			requestAnimationFrame((t) => this.updateProgress(t));

			window.addEventListener('created-slider-progress-group', () => {
				const btns = this.progressGroupTarget.querySelectorAll('.slider-progress-group__number');
				btns.forEach(btn => {
					btn.addEventListener('click', () => {
						const index = parseInt(btn.dataset.index);
						this._progress = 0;
						this._swiper.slideTo(index);
						this.progressGroupTarget.setAttribute('data-slider-progress-group-current-value', this.getCurrent());
						this.progressGroupTarget.setAttribute('data-slider-progress-group-progress-value', this._progress);
					});
				});
			});
		}

		if (this.hasPnWrapperTarget) {
			this.togglePaginationNavigation();

			window.addEventListener('resize', () => {
				this.togglePaginationNavigation();
			});
		}
	}

	togglePaginationNavigation() {
		if (this._swiper.isLocked) {
			this.pnWrapperTarget.style.display = 'none';
		} else {
			this.pnWrapperTarget.style.display = null;
		}
	}

	updateCurrent() {
		if (this._swiper && this.hasCurrentCountTarget) {
			let count = this._config.slidesPerView;
			count = count === 'auto' ? 1 : count;

			this.currentCountTargets.forEach(currentCount => {
				if (this._config.breakpoints) {
					for (const [breakpoint, value] of Object.entries(this._config.breakpoints)) {
						if (window.screen.width >= parseInt(breakpoint)) {
							count = value.slidesPerView;
							count = count === 'auto' ? 1 : count;
						}
					}
				}

				count = Math.floor(count);
				currentCount.textContent = count;
			});

			this._swiper.on('slideChange', (swiper) => {
				this.currentCountTargets.forEach(currentCount => {
					currentCount.textContent = count + swiper.realIndex;
				});
			});
		}
	}

	updateProgress(timestamp) {
		if (!this._progressTimestamp) {
			this._progressTimestamp = timestamp;
		}

		const elapsed = timestamp - this._progressTimestamp;

		if (this._swiper.autoplay.running && elapsed >= 200) {
			this._progress += this._progressAddition;
			this.progressGroupTarget.setAttribute('data-slider-progress-group-progress-value', Math.ceil(this._progress * 100));
			this._progressTimestamp = timestamp;
		}

		requestAnimationFrame((t) => this.updateProgress(t));
	}

	getCurrent() {
		return this._swiper.realIndex + 1;
	}
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get values() {
		return {
			current: Number,
			total: Number,
			progress: {
				type: Number,
				default: 0,
			},
		};
	}

	initialize() {
		this._ready = false;
	}

	connect() {
		this._createDom();
		this.update();

		this._ready = true;
	}

	update() {
		this.element.children[this.currentValue - 1].classList.add('active');
	}

	currentValueChanged(value) {
		if (!this._ready) {
			return;
		}

		for (let i = 0; i < this.element.children.length; i++) {
			this.element.children[i].classList.remove('active');
			this.element.children[i].children[1].children[0].style.width = '0%';
		}

		this.element.children[value - 1].classList.add('active');
	}

	progressValueChanged(value) {
		if (!this._ready) {
			return;
		}

		this.element.children[this.currentValue - 1].children[1].children[0].style.width = `${value}%`;
	}

	_createDom() {
		const root = this.element;

		for (let i = 1; i <= this.totalValue; i++) {
			const group = document.createElement('div');
			group.classList.add('slider-progress-group');

			const number = document.createElement('button');
			number.setAttribute('type', 'button');
			number.classList.add('slider-progress-group__number');
			number.textContent = i < 10 ? `0${i}` : i;
			number.setAttribute('data-index', i);

			const progress = document.createElement('div');
			progress.classList.add('slider-progress', 'slider-progress-group__progress');

			const progressBar = document.createElement('div');
			progressBar.classList.add('slider-progress__bar');

			progress.appendChild(progressBar);

			group.appendChild(number);
			group.appendChild(progress);

			root.appendChild(group);
		}

		const event = new CustomEvent('created-slider-progress-group');
		window.dispatchEvent(event);
	}
}

import { slideToggle } from '../helpers';

export function initMobileMenu()
{
	const mobileMenuButton = document.getElementById('openMobileMenu');
	const tabletMenuButton = document.getElementById('openTabletMenu');

	setTimeout(() => {
		setSubmenusPosition();
	}, 1000);

	window.addEventListener('resize', () => {
		setSubmenusPosition();
	});

	if (tabletMenuButton) {
		tabletMenuButton.addEventListener('click', e => {
			e.preventDefault();
			document.body.classList.toggle('show-menu--tablet');
		});
	}

	if (mobileMenuButton) {
		mobileMenuButton.addEventListener('click', e => {
			e.preventDefault();
			document.body.classList.toggle('show-menu');
		});

		document.getElementById('closeMenu').addEventListener('click', () => {
			document.body.classList.remove('show-menu');
			document.body.classList.remove('show-menu--tablet');
		});

		if (window.screen.width <= 991) {
			let toggleSubMenu = document.createElement('button');
			toggleSubMenu.className = 'toggle-sub-menu';
			toggleSubMenu.innerHTML = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
					'<mask id="mask0_2109_66118" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">\n' +
					'<rect width="24" height="24" fill="#D9D9D9"/>\n' +
					'</mask>\n' +
					'<g mask="url(#mask0_2109_66118)">\n' +
					'<path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" fill="currentColor"/>\n' +
					'</g>\n' +
					'</svg>\n';

			document.addEventListener('click', e => {
				if (e.target) {
					if (e.target.classList.contains('toggle-sub-menu') || (e.target.tagName.toLowerCase() === 'a' && e.target.getAttribute('href') === '#')) {
						e.target.parentNode.classList.toggle('active');
						slideToggle(e.target.parentNode.querySelector(':scope > .sub-menu'));
					} else if (e.target.tagName.toLowerCase() === 'li') {
						const a = e.target.querySelector(':scope > a[href="#"]');

						if (a) {
							e.target.classList.toggle('active');
							slideToggle(e.target.querySelector(':scope > .sub-menu'));
						}
					}
				}
			});

			let parents = document.querySelectorAll('.menu .menu-item-has-children');
			Array.from(parents).forEach(p => {
				if (p.querySelector(':scope > .toggle-sub-menu') === null) {
					let a = p.querySelector('a');
					a.insertAdjacentElement('afterend', toggleSubMenu.cloneNode(true));
				}
			});
		}
	}

	if (document.querySelector('.mobile-nav__menu')) {
		window.addEventListener('click', (e) => {
			if (!(document.querySelector('.mobile-nav__menu').contains(e.target) || document.querySelector('#openTabletMenu').contains(e.target) || document.querySelector('#openMobileMenu').contains(e.target)) && (document.body.classList.contains('show-menu') || document.body.classList.contains('show-menu--tablet'))){
				document.body.classList.remove('show-menu');
				document.body.classList.remove('show-menu--tablet');
			}
		});
	}
}

function setSubmenusPosition() {
	const subMenus = document.querySelectorAll('.navbar .sub-menu');
	subMenus.forEach(subMenu => {
		const outOfViewPort = isOutOfViewport(subMenu);

		if (outOfViewPort.right > 0) {
			const offset = (outOfViewPort.right) * -1;
			subMenu.style.left = `calc(${offset}px - 4vw)`;
		}
	});
}

function isOutOfViewport (elem)
{
	let bounding = elem.getBoundingClientRect();
	let out = {};
	out.top = bounding.top;
	out.left = bounding.left;
	out.bottom = bounding.bottom - (window.innerHeight || document.documentElement.clientHeight);
	out.right = bounding.right - (window.innerWidth || document.documentElement.clientWidth);

	return out;
}

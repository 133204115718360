import { Controller } from '@hotwired/stimulus';
import Axios from '../axios';

export default class extends Controller {
	static get targets () {
		return ['select', 'membershipsWrapper'];
	}

	connect() {
		this.currentLanguageCode = document.documentElement.lang.split('-')[0];
		this.selectTargets.forEach(select => {
			select.addEventListener('change', () => {
				this.renderMemberships(select.value);
			});
		});
	}

	async renderMemberships(id) {
		this.membershipsWrapperTargets.forEach(membershipsWrapper => {
			//membershipsWrapper.innerHTML = '';
			membershipsWrapper.classList.add('loading-block');
		});

		let url = `/wp-json/api/clubs/${id}/memberships/render?rest_language=${this.currentLanguageCode}`;

		if (this.element.dataset.pid) {
			url = `/wp-json/api/clubs/${id}/memberships/render?rest_language=${this.currentLanguageCode}&pid=${this.element.dataset.pid}`;
		}

		let response = await Axios.get(url);
		response = response.data.data;

		this.membershipsWrapperTargets.forEach(membershipsWrapper => {
			membershipsWrapper.classList.remove('loading-block');
			membershipsWrapper.innerHTML = response.html.trim();
		});
	}
}

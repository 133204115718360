import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
import TomSelect_check_all from '../components/tom-check-all';

export default class extends Controller {
	connect() {
		this.load();
	}

	initialize() {
		this._tomselect = null;
	}

	contactFormHandle(e) {
		const value = e.currentTarget.value;
		const cancelFields = document.querySelectorAll('.cancel');
		const nonCancelFields = document.querySelectorAll('.non-cancel');

		if (value.includes('contract_termination')) {
			nonCancelFields.forEach((field) => {
				field.classList.add('active');
			});

			cancelFields.forEach((field) => {
				field.classList.add('active');
			});
		} else {
			cancelFields.forEach((field) => {
				field.classList.remove('active');
			});

			nonCancelFields.forEach((field) => {
				field.classList.remove('active');
			});
		}
	}

	async load() {
		TomSelect.define('check_all', TomSelect_check_all);
		const additionalConfig = this.element.dataset.config ? JSON.parse(this.element.dataset.config) : {};
		const expandParent = additionalConfig.expandParent || false;
		const setSelectOptions = additionalConfig.setSelectOptions || false;
		if (additionalConfig.expandParent) {
			delete additionalConfig.expandParent;
		}

		if (additionalConfig.setSelectOptions) {
			delete additionalConfig.setSelectOptions;
		}

		if (setSelectOptions) {
			this.element.addEventListener('change', () => {
				const target = document.querySelector(setSelectOptions);
				if (target) {
					target.tomselect.clear();
					target.tomselect.clearOptions();

					let newOptions = null;

					if (this.element.selectedOptions.length > 0) {
						let arr = [...this.element.selectedOptions];
						newOptions = arr[0].dataset.options;
					}

					newOptions = newOptions ? JSON.parse(newOptions) : null;

					if (newOptions) {
						target.tomselect.addOptions(newOptions);
					}
				}
			});
		}

		const config = {
			hideSelected: false,
			render: {
				option(data, escape) {
					return `
                  <div>
                    <div>
                        <div class="option-title">${escape(data.text)} <span class="option-title__addition">${data.addition ? escape(data.addition) : ''}</span></div>
                        <div class="option-description">${data.description ? escape(data.description) : ''}</div>
										</div>
                  </div>
              `;
				},
			},
			...additionalConfig
		};

		if (additionalConfig.data_url) {
			config.valueField = 'id';
			config.labelField = 'title';
			config.searchField = 'title';
			config.load = function (query, callback) {
				const url = additionalConfig.data_url + '?q=' + encodeURIComponent(query) + '&rest_language=' + document.documentElement.lang.split('-')[0];
				fetch(url)
						.then(response => response.json())
						.then(json => {
							callback(json.data);
						}).catch(()=>{
					callback();
				});
			};
			config.render = {
				option (item, escape) {
					return `
                  <div>
                    <div>
                        <div class="option-title">${escape(item.title)}</div>
                        <div class="option-description">${item.description ? escape(item.description) : ''}</div>
										</div>
                  </div>
              `;
				}
			};
		}


		this._tomselect = new TomSelect(this.element, Object.keys(config).length > 0 ? config : undefined);

		if (expandParent) {
			this._tomselect.on('dropdown_open', () => {
				this.element.parentElement.parentElement.classList.add('w-100');
			});

			this._tomselect.on('dropdown_close', () => {
				this.element.parentElement.parentElement.classList.remove('w-100');
			});
		}
	}
}

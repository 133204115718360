import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const eventType = this.element.dataset.event || 'click';
        for (const tab of this.element.querySelectorAll('.tab')) {
            tab.addEventListener(eventType, () => this.toggleTab(tab));
        }
    }

    toggleTab(tab) {
        // Disable tab functionality if the tab has no data-tab, used for buttons as tabs.
        if (!tab.getAttribute('data-tab')) {
            return;
        }

        /*const parent = tab.parentNode.parentNode;

        for (const tabInner of parent.querySelectorAll('.tab')) {
            tabInner.classList.remove('active');
        }

        for (const tabContent of parent.querySelectorAll('.tab-content')) {
            tabContent.classList.remove('active');
        }*/

        const contents = this.element.querySelectorAll(`.${tab.getAttribute('data-tab')}`);
        const tabContents = this.element.querySelectorAll('.tab-content');
        tabContents.forEach(tabContent => {
            tabContent.classList.add('d-none');
        });

        contents.forEach(content => {
            content.classList.remove('d-none');
            content.classList.add('d-block');
        });
    }
}
